import {createApp} from 'vue'

import App from './App.vue'
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import ApiService from "@/core/services/ApiService";
import "bootstrap";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import i18n from "@/core/plugins/i18n";
import {initInlineSvg} from "@/core/plugins/inline-svg";
import {BaseService} from "@/core/services/BaseService";
import permission from "@/core/directives/permission";
import {Actions} from "@/store/enums/StoreEnums";
import {createPinia} from 'pinia'
import {globalCookiesConfig} from 'vue3-cookies';
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import {initApexCharts} from "@/core/plugins/apexcharts";

const app = createApp(App)
BaseService._axios.interceptors.response.use((response) => {
  const status = response.status
  if (status === 201 || status === 202) {
    useToast().success('Save successfully', {position: 'top-right'})
  }
  return response
}, error => {
  const response = error.response;
  if (response.status === 422) {
    store.dispatch(Actions.CREATE_FORM_ERROR, response.data.errors)
  }
  if (response.status === 400) {
    useToast().error(response.data.message, {position: 'top-right'})
  }
  if (response.status >= 500) {
    useToast().error(response.data.message, {position: 'top-right'})
  }
  return Promise.reject(error.response)
})
initInlineSvg(app);

globalCookiesConfig({
  expireTimes: "7d",
  path: "/",
  domain: process.env.VUE_APP_DOMAIN,
});

const clickOutside = {

  mounted: (el, binding) => {
    el.clickOutsideEvent = event => {
      if (event.target.dataset.target === 'click') return;
      if (!el.contains(event.target)) {
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

app.directive("has-access", permission)
app.directive("click-outside", clickOutside)
app.use(router)
app.use(VueSweetalert2);
app.use(store)
app.use(createPinia())
app.use(ElementPlus);
app.use(i18n)

initApexCharts(app);

ApiService.init(app);
app.mount('#app')
