import ApiService from "@/core/services/ApiService";
import JwtService, {
  getCurrentCompany,
  getResetPassword,
  getWorkspaceId,
  saveCompany,
  saveWorkspace
} from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import AuthService from "@/core/services/AuthService";
import type {ILoginResponse} from "@/core/response/ILoginResponse";


export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: any;
  isAuthenticated: boolean;
  isVerifyCode: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user: any = {};
  isAuthenticated = !!JwtService.getToken();
  isResetPassword = JwtService.getResetPassword();
  isVerifyCode = false;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): any {
    return this.user;
  }

  get isAdmin(): boolean {
    return this.user.admin
  }

  get getVerifyCode(): boolean {
    return this.isVerifyCode;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentication errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](loginResponse: ILoginResponse) {
    this.isAuthenticated = true;
    JwtService.saveToken(loginResponse.accessToken);
    JwtService.saveResetPassword(loginResponse.resetPassword);
    ApiService.setHeader();
  }

  @Mutation
  async [Mutations.SET_PROFILE](user) {
    this.user = user;
    await saveCompany(user.currentCompanyId)
    await saveWorkspace(user.currentWorkspaceId)
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user[password] = password;
  }

  @Mutation
  async [Mutations.PURGE_AUTH]() {
    console.log("logout")
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    await JwtService.destroyToken();
  }

  @Mutation
  [Mutations.SET_VERIFY_CODE](isVerifyCode: boolean) {
    this.isVerifyCode = isVerifyCode;
  }

  @Action
  [Actions.LOGIN](credentials) {
    return AuthService.login(credentials).then(res => {
      if (res.verifyCode) {
        this.context.commit(Mutations.SET_VERIFY_CODE, res.verifyCode);
        throw new Error("verifyCode")
      } else {
        this.context.commit(Mutations.SET_AUTH, res)
      }
    }).catch(({response}) => {
      this.context.commit(Mutations.SET_ERROR, response.data.message)
      throw response
    })
  }

  @Action
  [Actions.VERIFY_USER](credentials) {
    return AuthService.verify(credentials).then(res => {
      this.context.commit(Mutations.SET_AUTH, res)
    }).catch(({response}) => {
      this.context.commit(Mutations.SET_ERROR, response.data.message)
      throw response
    })
  }

  @Action
  [Actions.LOGOUT]() {
    console.log("actions logout")
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  async [Actions.LOAD_PROFILE]() {
    await AuthService.profile().then(async res => {
      await this.context.commit(Mutations.SET_PROFILE, res)
    })
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({data}) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({response}) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    const params = {
      params: {
        ...payload,
      },
    };
    return ApiService.query("forgot_password", params)
      .then(({data}) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({response}) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (!JwtService.getToken()) {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
