import {BaseService} from "@/core/services/BaseService";
import {buildFormData} from "@/core/helpers/functions";

class DocService extends BaseService<any> {
  endpoint = '/doc';

  async updateStatus(id, status) {
    return await this.http().put(this.endpoint + "/" + id + "/status?status=" + status).then(res => {
      return res.data
    })
  }

  async updateOrder(id, docId) {
    return await this.http().put(this.endpoint + "/" + id + "/order?docId=" + docId).then(res => {
      return res.data
    })
  }

  async menu() {
    return await this.http().get(this.endpoint + "/menu").then(res => {
      return res.data
    })
  }

  async sections(id) {
    return await this.http().get(this.endpoint + "/" + id + "/sections").then(res => {
      return res.data
    })
  }

  async docDrafts(id) {
    return await this.http().get(this.endpoint + "/" + id + "/drafts").then(res => {
      return res.data
    })
  }

  async publishDraft(id, model) {
    return await this.http().put(this.endpoint + "/" + id + "/publish-draft", model).then(res => {
      return res.data
    })
  }

  async histories(id) {
    return await this.http().get(this.endpoint + "/" + id + "/histories").then(res => {
      return res.data
    })
  }

  async folders(id) {
    return await this.http().get(this.endpoint + "/" + id + "/folders").then(res => {
      return res.data
    })
  }

  async files(id) {
    return await this.http().get(this.endpoint + "/" + id + "/files").then(res => {
      return res.data
    })
  }

  async upload(data) {
    const formData = buildFormData(data)
    return await this.uploadHttp().post(this.endpoint + "/files", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      return res.data;
    })
  }

  async download(id) {
    return await this.http().get(this.endpoint + "/files/" + id + "/download",
      {responseType: "blob"}).then(res => {
      return res;
    })
  }

  async getUrl(id) {
    return await this.http().get(this.endpoint + "/files/" + id + "/url",).then(res => {
      return res;
    })
  }

  async updateFaq(id, status) {
    return await this.http().put(this.endpoint + "/" + id + "/faq?faq=" + status).then(res => {
      return res.data
    })
  }

  async getFAQ(id) {
    return await this.http().get(this.endpoint + "/" + id + "/faq").then(res => {
      return res.data;
    })
  }

  async relatedDocuments(id) {
    return await this.http().get(this.endpoint + "/" + id + "/related-documents").then(res => {
      return res.data
    })
  }

  async getHelp(id) {
    return await this.http().get(this.endpoint + "/" + id + "/help").then(res => {
      return res.data;
    })
  }

  async getLink(id) {
    return await this.http().get(this.endpoint + "/" + id + "/link").then(res => {
      return res.data;
    })
  }

  async getLibraryHelp() {
    return await this.http().get(this.endpoint + "/library/help").then(res => {
      return res.data;
    })
  }

  async createLibraryHelp() {
    return await this.http().post(this.endpoint + "/library/help").then(res => {
      return res.data;
    })
  }

  async getInternalLinks() {
    return await this.http().get(this.endpoint + "/internal-links").then(res => {
      return res.data
    })
  }
}

export default new DocService();