import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import DocService from "@/core/services/DocService";
import {activeMenu} from "@/core/helpers/functions";
import {useCookies} from "vue3-cookies";

const {cookies} = useCookies();
@Module
export default class DocModule extends VuexModule {
  menuData: any = []

  pageEntity: any = {
    isLoading: true,
    data: {},
  };

  training: any = {
    isLoading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }

  sections: any = [];

  files: any = [];
  selectedDoc = {};
  pages: any = {
    isLoading: true,
    data: {
      total: 0,
      data: [],
      pages: 0,
    }
  }


  @Action
  [Actions.LOAD_FILES](id) {
    DocService.files(id).then(res => {
      this.context.commit(Mutations.SET_FILES, res);
    })
  }

  @Action
  [Actions.LOAD_SECTIONS](id) {
    DocService.sections(id).then(res => {
      this.context.commit(Mutations.SET_SECTIONS, res);
    })
  }

  @Action
  [Actions.LOAD_MENU]() {
    DocService.menu().then(res => {
      this.context.commit(Mutations.SET_MENU, res);
    })
  }

  @Action
  [Actions.LOAD_PAGES](filter: any) {
    this.context.commit(Mutations.SET_PAGES, {
      isLoading: true,
      data: {
        total: 0,
        data: [],
        pages: 0,
      }
    })
    DocService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_PAGES, {isLoading: true, data: res})
    })
  }

  @Action
  [Actions.LOAD_TRAINING](filter: any) {
    this.context.commit(Mutations.SET_TRAINING, {
      isLoading: true,
      data: {
        total: 0,
        data: [],
        pages: 0,
      }
    })
    DocService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_TRAINING, {isLoading: true, data: res})
    })
  }

  @Action
  [Actions.LOAD_PAGE](id) {
    this.context.commit(Mutations.SET_PAGE_ENTITY, {isLoading: true, data: {}})
    DocService.get(id).then(res => {
      this.context.commit(Mutations.SET_PAGE_ENTITY, {isLoading: false, data: res})
    })
  }

  @Action
  [Actions.LOAD_COLLECTIONS](filter: any) {
    DocService.findAll<any>(filter).then(res => {
      res.data.forEach(item => this.context.commit(Mutations.SET_PAGE, item))
    })
  }

  @Action
  [Actions.LOAD_SUB_MENU](item) {
    this.context.commit(Mutations.SET_ACTIVE_PAGE, item)
  }

  @Action
  [Actions.ADD_PAGE](data) {
    this.context.commit(Mutations.SET_PAGE, data)
  }

  @Mutation
  [Mutations.SET_SECTIONS](data) {
    this.sections = data;
    const selectedDoc = cookies.get('SELECTED_DOC');
    if (selectedDoc) {
      const select = this.sections.find(item => item.id === selectedDoc);
      if (select) {
        this.selectedDoc = select;
      } else {
        this.selectedDoc = this.pageEntity.data
      }
    }

  }

  @Mutation
  [Mutations.SET_FILES](data) {
    this.files = data;
  }

  @Mutation
  [Mutations.DELETE_PAGE_ITEM](page) {
    if (page.type === 'COLLECTION') {
      this.menuData = this.menuData.filter(item => item.id !== page.id);
    } else if (page.type === 'FOLDER') {
      this.menuData.forEach(item => {
        if (item.id === page.parentId) {
          item.children = item.children.filter(child => child.id !== page.id)
        }
      })
    } else {
      this.menuData.forEach(item => {
        if (item.id === page.parentId) {
          item.children = item.children.filter(child => child.id !== page.id)
        } else {
          item.children.forEach(i => {
            if (i.id === page.parentId) {
              i.children = i.children.filter(l => l.id !== page.id)
            }
          })
        }
      })
    }
  }

  @Mutation
  [Mutations.SET_TRAINING](payload) {
    this.training.isLoading = payload.isLoading;
    this.training.data = payload.data
  }

  @Mutation
  [Mutations.SET_PAGE_TO_LIST](item) {
    if (this.pageEntity.data.id === item.parentId) {
      this.pages.data.data.push(item)
    }
  }

  @Mutation
  [Mutations.SET_PAGES](payload) {
    this.pages.isLoading = payload.isLoading;
    this.pages.data = payload.data
  }

  @Mutation
  [Mutations.SET_PAGE_ITEMS](payload) {
    const menu: any = [];
    payload.data.forEach(item => {
      switch (item.type) {
        case 'FOLDER':
          menu.push({
            order: item.order,
            status: item.status,
            name: item.name,
            id: item.id,
            type: item.type,
            active: false,
            children: [],
            parentId: item.parentId,
          })
          break
        case'COLLECTION':
          menu.push({
            order: item.order,
            status: item.status,
            name: item.name,
            id: item.id,
            type: item.type,
            active: false,
            children: [],
          });
          break;
        case 'PAGE':
          menu.push({
            order: item.order,
            children: [],
            status: item.status,
            name: item.name,
            id: item.id,
            type: item.type,
            parentId: item.parentId,
          });
          break;
      }
    })
    this.menuData.filter(item => {
      if (item.id === payload.item.id) {
        item.children = menu;
      } else if (item.type === 'COLLECTION' && item.children.find(x => x.id === payload.item.id)) {
        item.children.find(x => x.id === payload.item.id).children = menu;
      }
    })
  }

  @Mutation
  [Mutations.SET_PAGE_ENTITY](payload) {
    const selectedId = cookies.get('SELECTED_DOC');
    this.pageEntity = payload;
    if (!selectedId) {
      this.selectedDoc = payload.data;
    }
  }

  @Mutation
  [Mutations.SET_ACTIVE_PAGE](item) {
    activeMenu(this.menuData, item);
    item.active = !item.active
  }

  @Mutation
  [Mutations.SET_ACTIVE_MENU](id) {
    console.log(id);
    this.menuData.forEach(collection => {
      if (collection.id === id) {
        collection.active = true;
        return true;
      }
      collection.children.forEach(folder => {
        if (folder.id === id) {
          collection.active = true;
          folder.active = true;
          return true;
        }
        folder.children.forEach(item => {
          if (item.id === id) {
            item.active = true;
            collection.active = true;
            folder.active = true;
            return true;
          }
        })
      })
    });
  }

  @Mutation
  [Mutations.SET_COLLECTIONS](pages) {
    const menu: any = [];
    pages.forEach(item => {
      switch (item.type) {
        case 'FOLDER':
          menu.push({
            order: item.order,
            status: item.status,
            name: item.name,
            id: item.id,
            type: item.type,
            active: false,
            children: [],
            parentId: item.parentId,
          })
          break
        case'COLLECTION':
          menu.push({
            order: item.order,
            status: item.status,
            name: item.name,
            id: item.id,
            type: item.type,
            active: false,
            children: [],
          });
          break;
        case 'PAGE':
          menu.push({
            order: item.order,
            children: [],
            status: item.status,
            name: item.name,
            id: item.id,
            type: item.type,
            parentId: item.parentId,
          });
          break;
      }
    })
    this.menuData = menu;
  }

  @Mutation
  [Mutations.SET_MENU](data) {
    this.menuData = data
  }

  @Mutation
  [Mutations.SET_PAGE](data: any) {
    let menu: any = {};
    switch (data.type) {
      case 'FOLDER':
        menu = {
          order: data.order,
          status: data.status,
          name: data.name,
          id: data.id,
          type: data.type,
          active: false,
          children: [],
          parentId: data.parentId,
        };
        break;
      case'COLLECTION':
        menu = {
          order: data.order,
          status: data.status,
          name: data.name,
          id: data.id,
          type: data.type,
          active: false,
          children: [],
        };
        break;
      case 'PAGE':
        menu = {
          order: data.order,
          children: [],
          status: data.status,
          name: data.name,
          id: data.id,
          type: data.type,
          parentId: data.parentId,
        };
        break;
    }
    if (data.type === 'COLLECTION') {
      if (!this.menuData.find(item => item.id == menu.id)) {
        this.menuData.push(menu)
      } else {
        const collection = this.menuData.find(item => item.id == menu.id);
        collection.name = menu.name
      }
    } else {
      if (data.parentId) {
        this.menuData.filter(item => {
          if (item.id === data.parentId) {
            if (!item.children.find(i => i.id === menu.id)) {
              item.children.push(menu);
            } else {
              const child = item.children.find(i => i.id === menu.id);
              child.name = menu.name
            }
            item.active = true
          } else if (item.type === 'COLLECTION' && item.children.find(x => x.id === data.parentId)) {
            const child = item.children.find(x => x.id === data.parentId)
            const last = child.children.find(k => k.id === menu.id)
            if (last) {
              last.name = menu.name
            } else {
              child.children.push(menu)
            }
          }
        })
      }
    }
  }

  @Mutation
  [Mutations.SET_SELECTED_DOC](payload) {
    if (payload.type === 'PAGE') {
      cookies.remove('SELECTED_DOC')
    } else {
      cookies.set('SELECTED_DOC', payload.id);
    }
    this.selectedDoc = payload;
  }

  @Mutation
  [Mutations.REMOVE_SELECTED_SECTION]() {
    cookies.remove('SELECTED_DOC')
    this.selectedDoc = this.pageEntity.data;
  }
}