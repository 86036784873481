import type {IFilter} from "@/core/entity/IFilter";
import type {ISearchFilter} from "@/core/entity/ISearchFilter";
import moment from "moment";

export function generatorQueryString(term: IFilter): string {
  let queryStr = '';
  if (term) {
    Object.keys(term).forEach(key => {
      if (term[key]) {
        if (key === 'paginationPage') {
          queryStr += `&offset=${(term.paginationPage - 1) * term.paginationSize}`;
        } else if (key === 'paginationSize') {
          queryStr += `&max=${term[key]}`;
        } else if (key === 'key') {
          const temp = term[key];
          Object.keys(temp).forEach(k => {
            queryStr += `&${k}=${temp[k]}`;
          });
        } else {
          queryStr += `&${key}=${term[key]}`;
        }
      }
    });
  }
  return queryStr;
}


export function generateSearchString(filter: ISearchFilter) {
  let queryStr = '';
  if (filter.query) {
    let e = "&q="
    for (const q of filter.query) {
      e += q.column + ":" + q.operator + ":" + q.value + "!"
    }
    queryStr += e;
  }
  if (filter.entity) {
    queryStr += "&e=" + filter.entity;
  }
  if (filter.fields) {
    queryStr += "&f=" + filter.fields.join(",");
  }
  if (filter.expand) {
    queryStr += "&ep=" + filter.expand.join(",");
  }
  queryStr += `&p=page:${filter.paginationPage - 1}!size:${filter.paginationSize}!sort:dateCreated:desc`
  return queryStr;
}

export function generateString(filter: any) {
  let queryStr = '';
  Object.keys(filter).forEach(key => {
    queryStr += `&${key}=${filter[key]}`;
  });
  return queryStr;
}


export function buildFormData(data: any) {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    if (data[key]) formData.set(key, data[key])
  });
  return formData;
}

export function makeDownloadLink(data) {
  const fileName = data.headers["content-disposition"].split("filename=")[1];
  const blob = new Blob([data.data], {type: data.headers['content-type']});
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function activeMenu(menu, item) {
  menu.forEach(a => {
    if (menu.includes(item))
      a.active = false;
    if (!a.children) return;
    a.children.forEach(b => {
      if (a.children.includes(item)) {
        b.active = false
      }
      if (!b.children) return false;
      b.children.forEach(c => {
        if (b.children.includes(item)) {
          c.active = false
        }
      })
    })
  });
}


export function mapCalendarItem(items: any) {
  return items.map(item => Object.assign(item, {
    id: item.id,
    title: item.title,
    date: moment(item.date).format("YYYY-MM-DD"),
    classNames: ['bg-secondary'],
    // backgroundColor: '#F5F8FA',
    borderColor: '#F5F8FA',
    extendedProps: {
      task: item.task
    }
  }))
}

export function formatDate(date: Date, format: string) {
  return moment(date).format(format)
}