import {BaseService} from "@/core/services/BaseService";
import {ENDPOINT} from "@/core/config/Constant";
import type {IFilter} from "@/core/entity/IFilter";
import {generatorQueryString} from "@/core/helpers/functions";

class UserService extends BaseService<any> {
  endpoint = ENDPOINT.USER;

  async loginHistory(id: string, filter: IFilter) {
    const queryString = generatorQueryString(filter);
    return await this.http().get(this.endpoint + "/" + id + "/login-history?" + queryString).then(res => {
      return res.data;
    })
  }


  public async present(data: any): Promise<any> {
    return this.http().post(this.endpoint + "/resent-mfa", data).then(res => {
      return res.data
    })
  }

  public async updateProfile(data): Promise<any> {
    return await this.http().put(this.endpoint + "/update-profile", data).then(res => {
      return res.data;
    })
  }

  public async updateNotification(data): Promise<any> {
    return await this.http().put(this.endpoint + "/update-notification", data).then(res => {
      return res.data;
    })
  }

  async updateStatus(id, status) {
    return await this.http().put(this.endpoint + "/" + id + "/status", {status: status}).then(res => {
      return res.data;
    })
  }

  async presetMfa(id, data) {
    return await this.http().put(this.endpoint + "/" + id + "/preset-mfa", data).then(res => {
      return res.data;
    })
  }

  async resetPassword(id) {
    return await this.http().put(this.endpoint + "/" + id + "/reset-password").then(res => {
      return res.data;
    })
  }

  public async login(data): Promise<any> {
    return this.http().post(this.endpoint + "/login", data).then(res => {
      return res.data
    })
  }

  public async register(data): Promise<any> {
    return this.http().post(this.endpoint + "/register", data).then(res => {
      return res.data
    })
  }

  public async verify(data): Promise<any> {
    return this.http().post(this.endpoint + "/verify", data).then(res => {
      return res.data
    })
  }

  public async changePassword(data): Promise<any> {
    return this.http().put(this.endpoint + "/change-password", data).then(res => {
      return res.data
    })
  }

  public async roles(id: string, filter: IFilter) {
    return await this.http().get(this.endpoint + "/" + id + "/roles").then(res => {
      return res.data
    })
  }

  public async addRole(id: any, model: any) {
    return await this.http().put(this.endpoint + "/" + id + "/roles", model).then(res => {
      return res.data
    })
  }

  async userLists(groupId, codes) {
    return await this.http().get(this.endpoint + "/user-list/" + groupId + "/teams?=" + codes).then(res => {
      return res.data
    })
  }

  async invite(data) {
    return await this.http().post(this.endpoint + "/invite", data).then(res => {
      return res.data
    })
  }

  async reInvite(id) {
    return await this.http().get(this.endpoint + "/invite/" + id).then(res => {
      return res.data
    })
  }

  async completeInvite(data) {
    return await this.http().put(this.endpoint + "/invite/confirm", data).then(res => {
      return res.data
    })
  }

  async joinConfirm(token) {
    return await this.http().put(this.endpoint + "/invite/join/confirm/" + token).then(res => {
      return res.data
    })
  }

  async preRegister(data) {
    return await this.http().post(this.endpoint + "/pre-register", data).then(res => {
      return res.data
    })
  }

  async getTimeZones() {
    return await this.http().get(this.endpoint + "/timezones").then(res => {
      return res.data;
    })
  }

  public async impersonal(email) {
    return this.http().get(this.endpoint + "/user-token?email=" + email).then(res => {
      return res.data;
    })
  }

  public async forgotPassword(data): Promise<any> {
    return this.http().post(this.endpoint + "/forgot-password", data).then(res => {
      return res.data
    })
  }

  public async registerCompany(data) {
    return await this.http().post(this.endpoint + "/register-company", data).then(res => {
      return res.data;
    })
  }
}

export default new UserService();