import {createStore} from "vuex";
import {config} from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import UserModule from "@/store/modules/UserModule";
import ValidationModule from "@/store/modules/ValidationModule";
import LoginModule from "@/store/modules/LoginModule";
import DocModule from "@/store/modules/DocModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    UserModule,
    ValidationModule,
    LoginModule,
    DocModule,
  },
});

export default store;
