import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [

  {
    path: "/",
    redirect: "/home",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/dashboard/:id/setting",
        name: "DashboardSetting",
        component: () => import("@/views/workspace/Dashboard.vue"),
        props: route => ({dashboardId: route.params.id})
      },
      {
        path: "/workspace:/id",
        name: "workspace",
        component: () => import("@/views/workspace/Workspace.vue"),
        children: [
          {
            path: "/workspace/:id/settings",
            name: "workspaceSetting",
            component: () => import("@/views/workspace/Setting.vue"),
          },
          {
            path: "/workspace/:id/members",
            name: "workspaceUsers",
            component: () => import("@/views/workspace/Members.vue"),
            props: route => ({spaceId: route.params.id})
          },
          {
            path: "/workspace/:id/dashboards",
            name: "workspaceDashboards",
            component: () => import("@/views/workspace/Dashboards.vue"),
            props: route => ({workspaceId: route.params.id})
          },

          {
            path: "/workspace/:id/action-logs",
            name: "workspaceActionLogs",
            component: () => import("@/views/workspace/ActionLogs.vue"),
            props: route => ({spaceId: route.params.id})
          },
        ]
      },
      {
        path: "/company/:id",
        name: "companySetting",
        component: () => import("@/views/user/company/Company.vue"),
        children: [
          {
            path: "/company/:id/settings",
            name: "companySetting",
            component: () => import("@/views/user/company/Setting.vue"),
          },
          {
            path: "/company/:id/workspaces",
            name: "companyWorkspaces",
            component: () => import("@/views/user/company/Workspaces.vue"),
          },
          {
            path: "/company/:id/users",
            name: "companyUsers",
            component: () => import("@/views/user/company/CompanyUser.vue"),
            props: route => ({companyId: route.params.id})
          },
          {
            path: "/company/:id/teams",
            name: "companyTeam",
            component: () => import("@/views/team/List.vue"),
            props: route => ({companyId: route.params.id})
          },
          {
            path: "/company/:id/action-logs",
            name: "companyActionLogs",
            component: () => import("@/views/user/company/ActionLogs.vue"),
          },
          {
            path: "/company/:id/logins",
            name: "companyLogins",
            component: () => import("@/views/user/company/LoginAudits.vue"),
            props: route => ({companyId: route.params.id})
          },
          {
            path: "/company/:id/permissions",
            name: "companyPermissions",
            component: () => import("@/views/user/company/Permissions.vue"),
            props: route => ({companyId: route.params.id})
          },
        ]
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/user/Profile.vue"),
        children: [
          {
            path: "/profile/overview",
            name: "profileOverview",
            component: () => import("@/views/user/profile/Overview.vue"),
          },
          {
            path: "/profile/settings",
            name: "profileSettings",
            component: () => import("@/views/user/profile/Settings.vue"),
          },
          {
            path: "/profile/change-password",
            name: "changePassword",
            component: () => import("@/views/user/profile/UpdatePassword.vue"),
          },
          {
            path: "/profile/notifications",
            name: "userNotifications",
            component: () => import("@/views/user/NotificationSetting.vue"),
          }
        ]
      },
      {
        path: "/teams/:id",
        name: "team",
        redirect: () => ({
          name: "teamSetting"
        }),
        component: () => import("@/views/team/Team.vue"),
        children: [
          {
            path: "/teams/:id/settings",
            name: "teamSetting",
            component: () => import("@/views/team/Setting.vue"),
          },
          {
            path: "/teams/:id/members",
            name: "teamMembers",
            component: () => import("@/views/team/Members.vue"),
            props: route => ({teamId: route.params.id})
          },
          {
            path: "/teams/:id/permissions",
            name: "teamPermissions",
            component: () => import("@/views/team/Permissions.vue"),
            props: route => ({teamId: route.params.id})
          },
          {
            path: "/teams/:id/logins",
            name: "teamLogins",
            component: () => import("@/views/team/Logins.vue"),
            props: route => ({teamId: route.params.id})
          },
          {
            path: "/teams/:id/action-logs",
            name: "teamActionLogs",
            component: () => import("@/views/team/ActionLogs.vue"),
            props: route => ({teamId: route.params.id})
          },
          {
            path: "/teams/:id/analytics",
            name: "teamAnalytics",
            component: () => import("@/views/team/ActionLogs.vue"),
            props: route => ({teamId: route.params.id})
          },
        ]
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/user/Users.vue"),
      },
      {
        path: "/users/:id",
        name: "user",
        redirect: to => ({
          name: "userActionLogs"
        }),
        component: () => import("@/views/user/User.vue"),
        children: [
          {
            path: "/users/:id/analytics",
            name: "userAnalytics",
            component: () => import("@/views/user/Analytics.vue"),
            props: route => ({userId: route.params.id})
          },
          {
            path: "/users/:id/logins",
            name: "userLogins",
            component: () => import("@/views/user/Logins.vue"),
            props: route => ({userId: route.params.id})
          },
          {
            path: "/users/:id/permissions",
            name: "userPermissions",
            component: () => import("@/views/user/Permissions.vue"),
            props: route => ({userId: route.params.id})
          },
          {
            path: "/users/:id/action-logs",
            name: "userActionLogs",
            component: () => import("@/views/user/ActionLogs.vue"),
            props: route => ({userId: route.params.id})
          },
        ]
      },
      {
        path: "/help-center",
        name: "helpCenter",
        component: () => import("@/views/doc/HelpLayout.vue"),
      },
      {
        path: "/help-center/:id",
        name: "helpLibrary",
        component: () => import("@/views/doc/PageDetail.vue"),
        props: route => ({pageId: route.params.id})
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/auth/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/auth/SignUp.vue"),
      },
      {
        path: '/verify-user/:id',
        name: 'verifyUser',
        component: () => import('@/views/auth/VerifyUser.vue'),
        props: route => ({token: route.params.id})
      },
      {
        path: "/forgot-password",
        name: "forgot",
        component: () =>
          import("@/views/auth/Forgot.vue"),
      },
    ],
  },
  {
    path: '/invite/:id',
    name: 'inviteUser',
    component: () => import('@/views/user/Invite.vue'),
    props: route => ({token: route.params.id})
  },
  {
    path: '/add-google-wallet',
    name: 'addGoogleWallet',
    component: () => import('@/views/GoogleWallet.vue'),
  },
  {
    path: '/confirm-invite/:id',
    name: 'confirmInviteUser',
    component: () => import('@/views/user/ConfirmInvite.vue'),
    props: route => ({token: route.params.id})
  },
  {
    path: '/admin',
    redirect: '/admin/users',
    component: () => import('@/layout/AdminLayout.vue'),
    children: [
      {
        path: "/admin/users",
        name: "adminUsers",
        component: () => import("@/views/admin/Users.vue")
      },
    ]
  },
  {
    path: '/update-value/:id/:token/:title',
    name: 'updateValue',
    component: () => import('@/views/metric/value/UpdateValue.vue'),
    props: route => ({id: route.params.id, token: route.params.token, title: route.params.title})
  },
  {
    path: '/update-value/:id/:token/:title/:type',
    name: 'updateValue',
    component: () => import('@/views/metric/value/UpdateValue.vue'),
    props: route => ({
      id: route.params.id,
      token: route.params.token,
      title: route.params.title,
      type: route.params.type
    })
  },

  {
    path: "/:companyId",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/:companyId/home",
        name: "companyHome",
        component: () => import("@/views/Home.vue"),
      },
    ],
  },
  {
    path: "/:workspaceId",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/:workspaceId/:pathMatch(.*)*",
        redirect: "/404",
      },
      {
        path: "/:workspaceId/dashboard/:id",
        name: "showDashboard",
        component: () => import("@/views/workspace/Dashboard.vue"),
        props: route => ({dashboardId: route.params.id})
      },
      {
        path: "/:workspaceId/goals",
        name: "goals",
        component: () => import("@/views/metric/Goals.vue"),
      },
      {
        path: "/:workspaceId/metric",
        name: "metricLayout",
        component: () => import("@/views/metric/MetricLayout.vue"),
        children: [
          {
            path: "/:workspaceId/metric/all",
            name: "allMetrics",
            component: () => import("@/views/metric/List.vue"),
          },
          {
            path: "/:workspaceId/metric/my",
            name: "myMetrics",
            component: () => import("@/views/metric/value/MyMetricValues.vue"),
          },
          {
            path: "/:workspaceId/metric/team",
            name: "teamMetrics",
            component: () => import("@/views/metric/value/TeamMetricValues.vue"),
          },
        ]
      },

      {
        path: "/:workspaceId/metric-group/:id",
        name: "metricGroups",
        component: () => import("@/views/metric/MetricGroup.vue"),
      },
      {
        path: "/:workspaceId/metric/:id",
        name: "metric",
        component: () => import("@/views/metric/Metric.vue"),
      },

      {
        path: "/:workspaceId/task",
        name: 'taskLayout',
        redirect: 'allTasks',
        component: () => import("@/views/task/TaskLayout.vue"),
        children: [
          {
            path: "/:workspaceId/task/all",
            name: 'allTasks',
            component: () => import("@/views/task/AllTasks.vue"),
          },
          {
            path: "/:workspaceId/task/pool",
            name: 'tasksPools',
            component: () => import("@/views/task/TaskPools.vue"),
          },
          {
            path: "/:workspaceId/task/approve",
            name: 'tasksToApprove',
            component: () => import("@/views/task/TaskToApproval.vue"),
          },
          {
            path: "/:workspaceId/task/my",
            name: 'myTasks',
            component: () => import("@/views/task/MyTasks.vue"),
          },
        ]
      },
      {
        path: "/:workspaceId/track/timers",
        name: 'timers',
        component: () => import("@/views/timer/List.vue"),
      },
      {
        path: "/:workspaceId/track/overview",
        name: 'overview',
        component: () => import("@/views/timer/Overview.vue"),
      },
      {
        path: "/:workspaceId/work",
        name: 'workLayout',
        component: () => import("@/views/work/WorkLayout.vue"),
        children: [
          {
            path: "/:workspaceId/work/all",
            name: 'workAll',
            component: () => import("@/views/work/List.vue"),
          },
          {
            path: "/:workspaceId/work/my",
            name: 'workMyTasks',
            component: () => import("@/views/work/Tasks.vue"),
          },
          {
            path: "/:workspaceId/work/team",
            name: 'workTeamTasks',
            component: () => import("@/views/work/MyTeamTasks.vue"),
          },
        ]
      },
      {
        path: "/:workspaceId/work/calendar",
        name: 'workCalendar',
        component: () => import("@/views/work/CalendarLayout.vue"),
        children: [
          {
            path: "/:workspaceId/work/calendar/weekly",
            name: 'weekCalendar',
            component: () => import("@/views/work/Weekly.vue"),
          },
          {
            path: "/:workspaceId/work/calendar/monthly",
            name: 'monthlyCalendar',
            component: () => import("@/views/work/Monthly.vue"),
          },
        ]

      },

      {
        path: "/:workspaceId/doc/:id",
        name: "pageDetail",
        component: () => import("@/views/doc/Page.vue"),
        props: route => ({id: route.params.id})
      },
      {
        path: "/:workspaceId/section/:id",
        name: "sectionDetail",
        component: () => import("@/views/doc/Page.vue"),
        props: route => ({id: route.params.id})
      },

      {
        path: "/:workspaceId/doc/:id/:section",
        name: "sectionDetails",
        component: () => import("@/views/doc/Page.vue"),
        props: route => ({id: route.params.id, section: route.params.section})
      },
      {
        path: "/:workspaceId/library",
        name: "libraryLayout",
        component: () => import("@/views/doc/LibraryLayout.vue"),
        children: [
          {
            path: "/:workspaceId/library/:id",
            name: "libraryDetail",
            component: () => import("@/views/doc/PageDetail.vue"),
            props: route => ({pageId: route.params.id})
          },
        ],
      },
      {
        path: "/:workspaceId/folder/:id",
        name: "folder",
        component: () => import("@/views/doc/PageDetail.vue"),
        props: route => ({pageId: route.params.id})
      },
      {
        path: "/:workspaceId/training",
        name: "trainingLayout",
        component: () => import("@/views/training/TrainingLayout.vue"),
        redirect: () => ({
          name: "trainingAll"
        }),
        children: [
          {
            path: "/:workspaceId/training/all",
            name: "trainingAll",
            component: () => import("@/views/training/AllTraining.vue"),
          },
          {
            path: "/:workspaceId/training/progress",
            name: "myTraining",
            component: () => import("@/views/training/MyTraining.vue"),
          },
        ]
      },
      {
        path: "/:workspaceId/training/:id",
        name: "trainingSubject",
        component: () => import("@/views/doc/PageDetail.vue"),
        props: route => ({pageId: route.params.id})
      },
      {
        path: "/:workspaceId/training-lib/:id",
        name: "trainingUser",
        component: () => import("@/views/training/Training.vue"),
        props: route => ({trainingId: route.params.id})
      },
      {
        path: "/:workspaceId/training-doc/:id",
        name: "trainingDocUser",
        component: () => import("@/views/training/TrainingDoc.vue"),
        props: route => ({id: route.params.id})
      },
    ]
  },
  {
    path: '/new-company',
    name: 'newCompany',
    component: () => import('@/views/user/RegisterCompany.vue'),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  // reset config to initial state
  const resetPage = ['/profile/change-password'];
  const isUpdateValue = to.path.includes("/update-value") || to.path.includes("/invite") || to.path.includes('/confirm-invite') || to.path.includes('/verify-user');
  const resetRequired = !resetPage.includes(to.path);
  if (resetRequired) {
    if (JwtService.getResetPassword()) {
      return next({
        name: 'changePassword',
      });
    }
  }
  const publicPages = ['/sign-in', '/forgot-password', '/sign-up', '/verify-user', '/add-google-wallet'];
  const authRequired = !publicPages.includes(to.path);
  const logged = JwtService.getToken()
  if (isUpdateValue) {
    next()
  } else {
    store.dispatch(Actions.VERIFY_AUTH);
    if (!logged && authRequired) {
      return next({
        path: '/sign-in',
        query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
      });
    }
    next()
  }
});
export default router;