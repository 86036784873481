export const ENDPOINT = {
  LOGIN: '/user/login',
  VERIFY: '/user/verify',
  PROFILE: "/user/me",
  PRODUCT: "/product",
  POLICY: "/policy",
  CASE: "/case",
  COMPANY: '/company',
  USER: "/user",
  ACTIVITY: "/activity",
  NOTE: "/note",
  LOGIN_HISTORY: "/login-history",
  DOCUMENT: '/document'
}
export const OPERATOR = {
  EQ: 'eq', // =
  LIKE: 'lk', // like
  IN: 'in',  // in
  GT: 'gt', // >
  LS: 'ls', // <
  GREQ: 'gte', // >-
  LSEQ: 'lte', // <=
  NE: 'ne', // !=
}

export const SEARCH_ENTITY = {
  POLICY: 'policy',
  POLICY_VIEW: 'pv',
  CASE_VIEW: 'cv',
  CASE: 'case',
  ACTIVITY: "activity",
  USER: "user",

}

export const CURRENCY = [
  'CAD', 'USD', 'EUR', 'GBP', 'MXN', 'CUP', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTC', 'BTN', 'BWP', 'BYN', 'BYR', 'BZD', 'CDF', 'CHF', 'CLF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EEK', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GEL', 'GGP', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'IMP', 'INR', 'IQD', 'IRR', 'ISK', 'JEP', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LTL', 'LVL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XAG', 'XAU', 'XCD', 'XDR', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZMW', 'ZWL', 'VES'
]

export const TASK_TEMPLATE_TYPE = [
  {name: 'Number', value: 'NUMBER'},
  {name: 'Text', value: 'TEXT'},
  {name: 'TextArea', value: 'TEXTAREA'},
  {name: 'Amount', value: 'AMOUNT'},
  {name: 'Yes/No', value: 'YES_NO'},
  {name: 'Breakdown', value: 'BREAKDOWN'},
  {name: 'File', value: 'FILE'},
]

export const COOKIE_KEY = {
  IMPERSONAL: "IMPERSONAL"
}


export const COMPANY_SIZE = [
  {value: 'SIZE_1_10', name: "1-10"},
  {value: 'SIZE_11_25', name: "11-25"},
  {value: 'SIZE_25_50', name: "25-50"},
  {value: 'SIZE_50_200', name: "50-200"},
  {value: 'SIZE_200_500', name: "200-500"},
  {value: 'SIZE_500', name: "500+"},
]
export const INDUSTRY = [
  {value: 'I_1', name: "IT and Software Services"},
  {value: 'I_2', name: "Management Consulting"},
  {value: 'I_3', name: "Marketing and Advertising"},
  {value: 'I_4', name: "Financial Services"},
  {value: 'I_5', name: "Legal Services"},
  {value: 'I_6', name: "Healthcare Services"},
  {value: 'I_7', name: "Education and Training"},
  {value: 'I_8', name: "Real Estate Services"},
  {value: 'I_9', name: "Hospitality and Tourism"},
  {value: 'I_10', name: "Transportation and Logistics"},
  {value: 'I_11', name: "Human Resources and Recruitment"},
  {value: 'I_12', name: "Public Relations and Communications"},
  {value: 'I_13', name: "Environmental Services"},
  {value: 'I_14', name: "Architectural and Design Services"},
  {value: 'I_15', name: "Event Planning Services"},
  {value: 'I_16', name: "Security Services"},
  {value: 'I_17', name: "Translation and Localization"},
  {value: 'I_18', name: "Media and Entertainment"},
  {value: 'I_19', name: "Non-Profit Organization Management"},
  {value: 'I_20', name: "Renewable and Environmental Services"},
  {value: 'I_21', name: "Other"},
]