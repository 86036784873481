import {useCookies} from 'vue3-cookies'

const {cookies} = useCookies();

const ID_TOKEN_KEY = "SW_TOKEN_ID" as string;
const ID_TOKEN_KEY_OLD = "SW_TOKEN_ID_OLD" as string;
const RESET_PASSWORD = "SW_RESET_PASSWORD" as string;
const COMPANY_ID = "COMPANY_ID" as string;
const WORKSPACE_ID = "WORKSPACE_ID" as string;


/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return cookies.get(ID_TOKEN_KEY);
};


export const setOldToken = (): void => {
  const token = cookies.get(ID_TOKEN_KEY);
  cookies.set(ID_TOKEN_KEY_OLD, token, "7d", "/", process.env.VUE_APP_DOMAIN)
}

export const getResetPassword = (): boolean => {
  return cookies.get(RESET_PASSWORD) === 'true'
}
/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  cookies.set(ID_TOKEN_KEY, token, "7d", "/", process.env.VUE_APP_DOMAIN)
};

export const saveResetPassword = (resetPassword: any): void => {
  cookies.set(RESET_PASSWORD, resetPassword, "7d", "/", process.env.VUE_APP_DOMAIN)
};
/**
 * @description remove token form localStorage
 */
export const destroyToken = async (): Promise<void> => {
  console.log("remove token")
  cookies.remove(ID_TOKEN_KEY, "/", process.env.VUE_APP_DOMAIN)
  cookies.remove(COMPANY_ID)
  cookies.remove(WORKSPACE_ID)
};
export const saveCompany = (id): void => {
  cookies.set(COMPANY_ID, id, 100000)
}

export const getCurrentCompany = (): string => {
  return cookies.get(COMPANY_ID)
}


export const saveWorkspace = (id): void => {
  cookies.set(WORKSPACE_ID, id, 100000)
}

export const getWorkspaceId = (): string => {
  return cookies.get(WORKSPACE_ID)
}

export default {
  getToken,
  saveToken,
  destroyToken,
  getResetPassword,
  saveResetPassword,
  saveCompany,
  getCurrentCompany,
  saveWorkspace,
  getWorkspaceId,
  setOldToken
};
